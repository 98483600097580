import { createApp, toRaw, watch } from 'vue'
import App from './App.vue'
import router from './router';
//import Vue from 'vue'
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
/*import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';*/

/*Bootstrap mdbbootstrap */
//import 'mdb-vue-ui-kit/css/mdb.min.css';
import './theme/custom.css';

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';


/* Theme variables */
import './theme/variables.css';

import { Http, HttpResponse } from '@capacitor-community/http';//we use this instead of socket because we don't want to open an anonymous socket and require some admin objects from the server prior to uid
/* Pinia Storage */
import { createPinia, defineStore, storeToRefs, mapState } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
const pinia = createPinia()
pinia.use(piniaPersist)

export interface systemInputElements {
  "inputName": {
    name: string,
    label: string,
    type: string,
    help: string,
    errorMessage: string,
    required: boolean,
    autocomplete: boolean,
    disable: boolean,
    output: string
  }
}
export interface bulletin {
  id: {
    id: number,
    label: string,
    name: string,
    description: string,
    icon: { 'data-prefix': '', 'data-icon': '' },
    inputs: [],
    syncableInputs: [{
      "inputName": "firstName" | "middleName" | "lastName" | "email" | "mobile",
      "active": boolean,
      "value": string | number,
    }],
    output: string
  }
}
export interface bulletinBoards {
  id: {
    id: number,
    name: string,
    bulletins: [number | string, number | string, number | string, number | string, number | string, number | string, number | string, number | string],
    devices: []
  }
}
export interface profileData {
  'firstName': '',
  'middleName': '',
  'lastName': '',
  'email': '',
  'mobile': ''
}
export interface devices {
  mac: {
    mac: string,
    name: string,
    wifiSsid: string,
    wifiPassword: string,
    bb: number,
  }
}
export interface settings {
  wifi: {
    ssid: string | undefined,
    password: string | undefined,
  }
}
export const useStore = defineStore('user', {
  state: () => {
    return {
      uid: '',
      systemInputElements: {} as systemInputElements,
      systemBulletins: {},//look at changing to array
      profileData: {} as profileData,
      profileImage: { url: '', base64: '' },
      bulletinBoards: {} as bulletinBoards,
      bulletins: {} as bulletin,
      devices: {} as devices,
      settings: {
        wifi: {
          ssid: undefined,
          password: undefined
        }
      } as settings,
      systemIcons: {},//keep as object as this imports into FA library plugin
      page: 'profile',
      newUser: false
    }
  },



  actions: {

    async getSystemInputs() {
      try {
        const response: any = JSON.parse(toRaw((await Http.request({ url: 'https://luco.net.au:4343/api/inputElements', headers: { 'Content-Type': 'application/json' }, method: 'GET' })).data))
        this.systemInputElements = response
      } catch (err) { console.log('an error occured getting systemInputs', err) }
    },
    async getSystemBulletins() {
      try {
        const response: HttpResponse = JSON.parse(toRaw((await Http.request({ url: 'https://luco.net.au:4343/api/systemBulletins', headers: { 'Content-Type': 'application/json' }, method: 'GET' })).data))
        this.systemBulletins = response
      } catch (err) { console.log('an error occured getting systemBulletins', err) }
    },
    async getCustomIcons() {
      try {
        const response: HttpResponse = JSON.parse(toRaw((await Http.request({ url: 'https://luco.net.au:4343/api/customIcons', headers: { 'Content-Type': 'application/json' }, method: 'GET' })).data))
        this.systemIcons = response
      } catch (err) { console.log('an error occured getting systemBulletins', err) }
    },
    getSystemVariables() {
      this.getSystemInputs()
      this.getSystemBulletins()
      this.getCustomIcons()
    },
    getData() {
      console.log('getting data')
      SocketInstance.emit('getBulletins')
      SocketInstance.emit('getProfile')
    },
    createBulletin(systemBulletin: string) {
      //console.log('creating bulletin', systemBulletin)
      if (SocketInstance) {
        SocketInstance.emit('createBulletin', systemBulletin)
      }
    },

  },
  persist: {
    enabled: true
  }
})




//import $ from 'jquery'
/** socket.io  */
import socketio, { Socket } from 'socket.io-client';
import VueSocketIO from 'vue-3-socket.io';//have a look at this. types errors occor with othe repos

let app: any



/** Firebase Authentication */

import firebase from 'firebase/compat/app';
import './theme/firebaseUI.css'
import * as firebaseui from 'firebaseui'

const firebaseConfig = {
  apiKey: "AIzaSyCtV3-_p3UGnTGMb7FyDGu6FjgY0sIhdZk",
  authDomain: "luco-ffeb6.firebaseapp.com",
  databaseURL: "https://luco-ffeb6.firebaseio.com",
  projectId: "luco-ffeb6",
  storageBucket: "luco-ffeb6.appspot.com",
  messagingSenderId: "814366099379",
  appId: "1:814366099379:web:2acfaf0f6de9b0f98b6dc6",
  measurementId: "G-V10FDLJ4PQ"
};
let SocketInstance: any
//let uid: any

//todo we now need to be able to use firebase functions and db and user.uid as global 

firebase.initializeApp(firebaseConfig);
firebase.auth().onAuthStateChanged(async function (user: any) {
  if (!app) {
    app = createApp(App)
      .use(IonicVue)
      .use(router)
      .use(pinia)
    router.isReady().then(() => {
      // app.component('font-awesome-icon', FontAwesomeIcon)
      app.mount('#app');
    })
  }
  const store = useStore()
  store.getSystemVariables()//todo look closer at this to load all system arrays on login and watch for changes??
  // console.log('uid', store.uid)
  //router guards
  router.beforeEach(async (to, from, next) => {
    if (to.name != 'auth' && !store.uid) next({ name: 'auth' }) // detect refresh reload with !from
    else next()
    const TO = to.name || 'profile'
    store.page = to.name != 'auth' ? TO.toString() : 'profile'
  })

  if (!user) {
    console.log('no user')
    router.replace('/auth').then(() => {
      let profileData: any = {}
      const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
      const uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: function (authResult: any) {
            if (store.uid != authResult.user.uid) {//if the current user is not the stored users data then reset the profileData
              store.$reset()
              store.uid = authResult.user.uid
              console.log('mismatch uid. Resetting stored profile data', store.profileData)
            }
            console.log('sign in successful', authResult.user.uid)
            if (authResult.additionalUserInfo.isNewUser === true) { //First Time Signup: 
              store.newUser = true
              if (authResult.additionalUserInfo.providerId !== "password") {
                profileData = {
                  firstName: authResult.additionalUserInfo.profile.given_name,
                  lastName: authResult.additionalUserInfo.profile.family_name,
                }
                /* todo handle image if Google or Facebook is signin option.
                                  if (authResult.user.photoURL) {
                                    let image = new Image
                                    image.setAttribute('crossorigin', 'anonymous'); //this may not be reqired for production? was needed for local testing
                                    image.src = authResult.user.photoURL
                                    image.onload = function () {
                                      ctx(image)
                                    }
                                    //  updateProfileimg(result.user.photoURL)
                                  }
                */
              }
              profileData.email = authResult.user.email
              profileData.providerId = authResult.additionalUserInfo.providerId
              store.profileData = profileData
            }
            // router.replace('/profile')
            return false;
          },
        },
        signInSuccessUrl: '',
        signInOptions: [{
          provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          scopes: [
            // 'instagram_basic',//requires Facebook app review
            // 'user_link',
            'public_profile',
            'email' //This is the one we need and it is provided by default
          ],
        },
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
        ]
      };
      // console.log(ui)
      ui.start('#firebaseui-auth-container', uiConfig);
      /**the below is to present seperate inputs for first and last names. GoogleUI only provides a single field for full name
       *can probably make this more elegant by creating custome login page.
             */
      let trigger = false //used to prevent mutation observer loop on clone and append event
      const elementToObserve: any = document.getElementById('firebaseui-auth-container')// $("#firebaseui-auth-container")[0]
      console.log(elementToObserve)
      const observer = new MutationObserver(function () { //observer function
        //Seperate first and last name in ui form
        const nameInput: any = document.getElementById('ui-sign-in-name-input')//$('.firebaseui-id-name')[0] //document.getElementsByName('name')[0] //listen for new signup name input to add seperated fname lname fields
        console.log(nameInput)
        // const nameLabel=document.querySelector("#firebaseui-auth-container > div > form > div.firebaseui-card-content > div:nth-child(3) > label")
        if (nameInput && trigger === false) {
          trigger = true //prevent infinite loop 
          const namediv: any = elementToObserve.querySelector('div > form > div.firebaseui-card-content > div:nth-child(3)') //$("#firebaseui-auth-container > div > form > div.firebaseui-card-content > div:nth-child(3)")[0]
          console.log(namediv)
          namediv.style.display = 'none'
          const setAttributes = (elements: any, attributes: any) => { //Set Attributes helper function
            Object.keys(attributes).forEach(function (name) {
              elements.setAttribute(name, attributes[name]);
            })
          }

          const container: any = elementToObserve.querySelector('div > form > div.firebaseui-card-content')//$("#firebaseui-auth-container > div > form > div.firebaseui-card-content")[0]
          const fnameDiv = document.createElement("div")
          setAttributes(fnameDiv, {
            class: 'namedivs'
          })
          const fnameInput: any = document.createElement("INPUT")
          setAttributes(fnameInput, {
            id: 'fname',
            name: 'First_Name',
            class: "nameinputs",
            type: 'text',
            autocomplete: "given-name",
            required: "true",
          })
          const fnameLabel = document.createElement("LABEL")
          setAttributes(fnameLabel, {
            for: 'fname',
            class: "namelabels"
          })
          fnameLabel.innerHTML = 'First name'

          const lnameDiv = document.createElement("div")
          setAttributes(lnameDiv, {
            class: 'namedivs'
          })
          const lnameInput: any = document.createElement("INPUT")
          setAttributes(lnameInput, {
            id: 'lname',
            name: 'Last_Name',
            class: "nameinputs",
            type: 'text',
            autocomplete: "family-name",
            required: "true",
          })
          const lnameLabel = document.createElement("LABEL")
          setAttributes(lnameLabel, {
            for: 'lname',
            class: "namelabels"
          })
          lnameLabel.innerHTML = 'Last name'
          const span1 = document.createElement("span")
          span1.className = "bar"
          fnameDiv.appendChild(fnameInput)
          fnameDiv.appendChild(fnameLabel)
          fnameDiv.appendChild(span1)
          const span2 = document.createElement("span")
          span2.className = "bar"
          lnameDiv.appendChild(lnameInput)
          lnameDiv.appendChild(lnameLabel)
          lnameDiv.appendChild(span2)

          container.insertBefore(fnameDiv, namediv)
          container.insertBefore(lnameDiv, namediv)

          fnameInput.addEventListener('input', function () {
            //toUpperCase($(this)) //call helper
            nameInput.value = `${fnameInput.value} ${lnameInput.value}`
            profileData.firstName = fnameInput.value
          })
          lnameInput.addEventListener('input', function () {
            // toUpperCase($(this))
            nameInput.value = `${fnameInput.value} ${lnameInput.value}`
            profileData.lastName = lnameInput.value
          })
        }

      });
      observer.observe(elementToObserve, {
        subtree: true,
        childList: true
      });
    })
  }
  else {
    if (!store.uid) {// noticed at times that store doesn't persist uid.
      store.uid = user.uid
    }
    SocketInstance = socketio('https://luco.net.au:4343', { auth: { token: user.uid } })
    app.use(new VueSocketIO({ connection: SocketInstance }))
    // console.log('store.inputFields=', store.inputs(), store.inputFields)

    router.replace(store.page || 'profile')
  }
})

//had to change nodejs file see https://github.com/MetinSeylan/Vue-Socket.io/issues/323

export const auth = firebase.auth()

export { SocketInstance }
//export { library }


import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/auth'
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/Auth.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/profile.vue')
  },
  {
    path: '/bulletin-boards',
    name: 'bulletin-boards',
    component: () => import('../views/bulletin-boards.vue')
  },
  {
    path: '/bulletins',
    name: 'bulletins',
    component: () => import('../views/bulletins.vue')
  },
  {
    path: '/devices',
    name: 'devices',
    component: () => import('../views/devices.vue')
  },
  /* {
     path: '/administration-view',
     name: 'administration-view',
     component: () => import('../views/administration-view.vue')
   },*/
  {
    path: '/administration-view/',
    component: () => import('../views/administration-view.vue'),
    children: [
      {
        path: '',
        redirect: '/admin-inputs',
      },
      {
        path: '/admin-inputs',
        component: () => import('../views/admin-inputs.vue'),
      },
      {
        path: '/admin-icons',
        component: () => import('../views/admin-icons.vue'),
      },
      {
        path: '/admin-bulletins',
        component: () => import('../views/admin-bulletins.vue'),
      },
    ],
  },
  /*{
    path: '/admin-inputs',
    name: 'admin-inputs',
    component: () => import('../views/admin-inputs.vue')
  },
  {
    path: '/admin-icons',
    name: 'admin-icons',
    component: () => import('../views/admin-icons.vue')
  }
}*/
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
